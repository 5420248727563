const BACKEND_URL = require("../services/api").BACKEND_URL;

export const placeOrder = async (order) => {
  try {
    const res = await fetch(`${BACKEND_URL}/api/place-order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(order),
    });
    const data = await res.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getOrder = async (order) => {
  try {
    const res = await fetch(`${BACKEND_URL}/api/get-order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(order),
    });
    const data = await res.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};
