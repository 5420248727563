import React from "react";
import MoonLoader from "react-spinners/MoonLoader";

function Loader() {
  return (
    <>
      <div className="z-[999] fixed top-0 left-0 right-0 bottom-0 h-full w-full bg-[#ffffff99] backdrop-blur-lg flex flex-col justify-center items-center">
        <MoonLoader color="#000" size={50} />
      </div>
    </>
  );
}

export default Loader;
