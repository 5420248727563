const BACKEND_URL = require("../services/api").BACKEND_URL;

export const getProducts = async (sellerId) => {
  try {
    const res = await fetch(`${BACKEND_URL}/api/products/${sellerId}`);
    const data = await res.json();
    return data;
  } catch (error) {
    console.log(error.response.data);
  }
};

export const getProduct = async (productId) => {
  try {
    const res = await fetch(`${BACKEND_URL}/api/product/${productId}`);
    const data = await res.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};
