import React, { useState, useContext, useEffect } from "react";
import cities from "../utils/cities.json";
import { subdomainContext, ThemeProvider } from "../services/context.js";
import MoonLoader from "react-spinners/MoonLoader";
import { getProduct } from "../api/productSlice.js";
import { placeOrder } from "../api/orderSlice.js";
import { getStore } from "../api/storeSlice.js";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import useFacebookPixel from "../services/usePixel.ts";
import { TrackableEventNameEnum } from "react-use-facebook-pixel";
import Loader from "./Loader.jsx";
function Order(props) {
  const subdomain = useContext(subdomainContext);
  const navigate = useNavigate();
  const pixel = useFacebookPixel();
  const data = useContext(ThemeProvider);
  const [isLoading, setIsLoading] = useState(true);
  const [store, setStore] = useState({});
  const [deliveryType, setDeliveryType] = useState("");
  const [fees, setFees] = useState("");
  const order = async () => {
    const customerId = new Date().getTime().toString();
    setIsLoading(true);
    try {
      const Cart = JSON.parse(localStorage.getItem("cart")) || [props.product];
      for (const item of Cart) {
        if (Cart) {
          const order = {
            productId: item._id,
            products: props.product ? props.product : Cart,
            quantity: props.quantity
              ? props.quantity
              : Cart.map((product) => product.quantity).reduce((a, b) => a + b),
            price: (props.price ? props.price : item.price) + (fees ? fees : 0),
            sellerId: props.sellerId
              ? props.sellerId
              : products.map((product) => product.sellerId)[0],
            name: state.name,
            wilaya: state.wilaya,
            wilayaId: state.wilayaId,
            senderWilaya: store?.store?.shopInfo?.wilaya || "",
            commune: state.commune,
            isDesk: deliveryType == 0 ? true : false,
            phone: state.phone,
            customerId,
            address: state.address,
          };
          await placeOrder(order);
        }
      }
      pixel?.trackEvent(TrackableEventNameEnum.Purchase, {
        currency: "USD",
        value: state.total,
        content_ids: products.map((product) => product._id),
      });
      setTimeout(() => {
        setIsLoading(false);
        toast.success("Commande effectuee avec succes");
      }, 1000);
      setTimeout(() => {
        localStorage.removeItem("cart");
        setState({ wilaya: "", commune: "", name: "", phone: "", total: 0 });
        navigate(`/success/${customerId}`);
      }, 1200);
    } catch {
      setIsLoading(false);
      toast.error("Un probleme est survenu");
    }
  };
  const [state, setState] = useState({
    wilaya: "",
    wilayaId: "",
    commune: "",
    name: "",
    phone: "",
    address: "",
    total: 0,
  });
  const [products, setProducts] = useState([]);
  useEffect(() => {
    const Cart = JSON.parse(localStorage.getItem("cart"));
    if (Cart) {
      for (const item of Cart) {
        const fetchProduct = async () => {
          const data = await getProduct(item._id);
          if (!data) return;
          setProducts((prev) => [
            ...prev,
            { quantity: item.quantity, ...data },
          ]);
        };
        fetchProduct();
      }
    }
    async function fetchStore() {
      const fetchStore = async () => {
        const data = await getStore(subdomain);
        if (!data) return;
        setStore(data);
        setIsLoading(false);
      };
      fetchStore();
    }
    fetchStore();
  }, []);
  const [commune, setCommune] = useState("");
  const [filteredCommunes, setFilteredCommunes] = useState([]);
  const [selectedWilayaCode, setSelectedWilayaCode] = useState("");
  const handleCommuneChange = (e) => {
    setCommune(e.target.value);
    setState({
      ...state,
      commune: e.target.value,
    });
  };
  const handleDivChange = (e) => {
    const selectedCode = e.target.value;
    const index = e.target.selectedIndex;
    const element = e.target.children[index];
    setState({
      ...state,
      wilaya: element.textContent,
      wilayaId: selectedCode,
    });
    setSelectedWilayaCode(selectedCode);
    const Communes = cities.filter((item) => item.wilaya_code === selectedCode);
    setFilteredCommunes(Communes);
  };
  const uniqueWilayas = Array.from(
    new Set(cities.map((item) => item.wilaya_code))
  ).map((code) => {
    const wilaya = cities.find((item) => item.wilaya_code === code);
    return {
      wilaya_code: wilaya.wilaya_code,
      wilaya_name_ascii: wilaya.wilaya_name_ascii,
    };
  });
  useEffect(() => {
    // get total price
    let total = 0;
    for (const product of JSON.parse(localStorage.getItem("cart")) || [
      props.product,
    ]) {
      total += product.price * product.quantity;
      setState((prev) => ({ ...prev, total }));
    }
  }, []);
  useEffect(() => {
    const fees = store?.store?.delivery.filter(
      (city) => city.id == state.wilayaId
    );
    if (fees) setFees(deliveryType == 0 ? fees[0]?.desktop : fees[0]?.home);
  }, [deliveryType, state]);
  return (
    <div className={`flex flex-col gap-3 mx-4 mt-4 ${props.className}`}>
      {isLoading && <Loader />}
      <h3 className="text-xl font-semibold">Compléter votre commande</h3>
      <div className="flex flex-row gap-3">
        <div className="flex flex-col w-full">
          <label>Nom</label>
          <input
            value={state.name}
            onChange={(e) =>
              setState((prev) => ({ ...prev, name: e.target.value }))
            }
            type="text"
            placeholder="Nom complet"
            className="w-[100%] px-4 py-2 rounded-lg"
            style={{ backgroundColor: `${data?.main_color}88` }}
            require
          />
        </div>
        <div className="flex flex-col w-full">
          <label>Numéro</label>
          <input
            value={state.phone}
            type="tel"
            onChange={(e) => {
              setState((prev) => ({ ...prev, phone: e.target.value }));
              if (!/^-?\d+$/.test(e.target.value)) {
                e.target.value = e.target.value.slice(0, -1);
              }
              if (e.target.value.charAt(0) != "0") {
                e.target.value = "0" + e.target.value;
              }
            }}
            maxLength="10"
            placeholder="Numero de telephone"
            className="px-4 py-2 rounded-lg w-full"
            style={{ backgroundColor: `${data?.main_color}88` }}
            required
          />
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <label htmlFor="recieverWilaya">Wilaya</label>
        <select
          id="recieverWilaya"
          value={selectedWilayaCode}
          className="px-4 py-2 rounded-lg"
          style={{ backgroundColor: `${data?.main_color}88` }}
          onChange={handleDivChange}
        >
          <option value={""}>Choisir la wilaya</option>
          {uniqueWilayas.map((wilaya, index) => (
            <option key={index} value={wilaya.wilaya_code}>
              {wilaya.wilaya_name_ascii}
            </option>
          ))}
        </select>
        <label htmlFor="recieverCommune">Commune</label>
        <select
          value={commune}
          onChange={handleCommuneChange}
          id="recieverCommune"
          className="px-4 py-2 rounded-lg"
          style={{ backgroundColor: `${data?.main_color}88` }}
        >
          <option value={""}>Choisir la commune</option>
          {filteredCommunes.map((commune, index) => (
            <option key={index} value={commune.commune_name_ascii}>
              {commune.commune_name_ascii}
            </option>
          ))}
        </select>
        <label>Type de livraison</label>
        <select
          className="px-4 py-2 rounded-lg"
          style={{ backgroundColor: `${data?.main_color}88` }}
          onChange={(e) => setDeliveryType(e.target.value)}
          value={deliveryType}
        >
          <option value={""}>Choisir le type de livraison</option>
          <option value={0}>Bureau</option>
          <option value={1}>Domicile</option>
        </select>
        <label htmlFor="recieverCommune">Address</label>
        <input
          value={state.address}
          onChange={(e) =>
            setState((prev) => ({ ...prev, address: e.target.value }))
          }
          type="text"
          placeholder="Address"
          className="w-[100%] px-4 py-2 rounded-lg"
          style={{ backgroundColor: `${data?.main_color}88` }}
          required
        />
      </div>
      <span
        className={`text-white ${
          state.commune &&
          state.wilaya &&
          state.name &&
          state.phone &&
          state.address &&
          state.phone.length === 10
            ? "pointer-events-auto"
            : "pointer-events-none opacity-40"
        } ${
          isLoading && "opacity-40"
        } bg-green-500 py-3 px-4 transition-all duration-300 rounded-lg flex items-center justify-center`}
        onClick={order}
      >
        {isLoading ? <MoonLoader size={15} /> : "Commander"}
      </span>
      <div
        className="flex flex-col gap-1 p-2 rounded-lg"
        style={{ backgroundColor: `${data?.main_color}88` }}
      >
        <p className="text-md">Résume de la commande</p>
        {state.commune && state.wilaya && state.name && state.phone && (
          <>
            <p className="text-xs">
              {state.name} • {state.phone}
            </p>
            <p className="text-xs">
              {state.wilaya} • {state.commune}
            </p>
          </>
        )}
        <p className="text-md">
          Prix: <font className="font-bold">{props.price || state.total}</font>{" "}
          DA
          <br />
          <font className="font-semibold text-gray-800 text-xs">
            + {(fees && fees) || ""}
            {fees && fees && " DA"} (frais de livraison)
          </font>
        </p>
      </div>
    </div>
  );
}

export default Order;
