import React, { useContext, useEffect, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { FaBagShopping, FaBars } from "react-icons/fa6";
import { ThemeContext } from "../services/context";
import { useNavigate } from "react-router-dom";
import invert from "invert-color";

function Header() {
  const data = useContext(ThemeContext);
  const [announcements, setAnnouncements] = useState([]);
  const navigate = useNavigate();
  const [cartQuantity, setCartQuantity] = useState(0);
  const invertedColor = invert(data?.secondary_color, true);

  useEffect(() => {
    setAnnouncements([data?.announcement1, data?.announcement2]);
  }, [data]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const items = JSON.parse(localStorage.getItem("cart")) || [];
      const quantity = items.reduce((acc, item) => acc + item.quantity, 0);
      setCartQuantity(quantity);
    });

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <div
        style={{ backgroundColor: data?.main_color }}
        className="text-center flex px-10 justify-between md:px-0 md:justify-around items-center py-10"
      >
        <FaBars
          style={{ color: data?.titles_color }}
          className="text-2xl cursor-pointer"
          onClick={() => navigate(`/`)}
        />
        <h1
          style={{ color: data?.titles_color }}
          className="text-3xl font-bold cursor-pointer"
        >
          {data?.shop_name}
        </h1>
        <span className="cursor-pointer" onClick={() => navigate(`/cart`)}>
          <FaBagShopping
            style={{ color: data?.titles_color }}
            className="text-2xl"
          />
          <span className="h-4 w-4 absolute mt-[-5px] rounded-full bg-red-500 text-xs text-white">
            {cartQuantity}
          </span>
        </span>
      </div>
      <Splide
        options={{
          rewind: true,
          speed: 1000,
          arrows: false,
          pagination: false,
          autoplay: true,
          autoScroll: {
            speed: 1000,
          },
        }}
      >
        {announcements[0] && (
          <SplideSlide
            className="flex justify-center items-center py-3 text-sm"
            style={{
              backgroundColor: data?.secondary_color,
              color: data?.titles_color,
            }}
          >
            <p style={{ color: invertedColor }}>{announcements[0]}</p>
          </SplideSlide>
        )}
        {announcements[1] && (
          <SplideSlide
            className="flex justify-center items-center py-3 text-sm"
            style={{
              backgroundColor: data?.secondary_color,
              color: data?.titles_color,
            }}
          >
            <p style={{ color: invertedColor }}>{announcements[1]}</p>
          </SplideSlide>
        )}
      </Splide>
    </>
  );
}

export default Header;
