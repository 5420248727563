import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  ThemeProvider,
  ProductsContext,
  LoadingContext,
} from "../services/context.js";
import Loader from "./Loader.jsx";
import useFacebookPixel from "../services/usePixel.ts";
import { TrackableEventNameEnum } from "react-use-facebook-pixel";

function Main() {
  const data = useContext(ThemeProvider);
  const navigate = useNavigate();
  const ProductsFromContext = useContext(ProductsContext);
  const isLoading = useContext(LoadingContext);
  const pixel = useFacebookPixel();
  const isSubdomain = true;
  useEffect(() => {
    pixel?.trackEvent(TrackableEventNameEnum.PageView, {
      contentName: "Home",
    });
  }, []);
  useEffect(() => {
    document.body.style.backgroundColor = `${data?.main_color}66`;
    if (isSubdomain) {
      let subdomain;
      const host = window.location.host;
      const arr = host
        .split(".")
        .slice(0, host.includes("localhost") ? -1 : -2);
      if (host.length > 0) subdomain = arr[0];
      if (!subdomain) {
        setTimeout(() => {
          window.location.href = "https://storebladi.com/";
        }, 100);
      }
    }
  });
  const goToProduct = (id) => {
    navigate(`/${id}`);
  };
  return (
    <div className="flex flex-col mt-10" key={data}>
      {isLoading && <Loader />}
      <div className="flex flex-row flex-wrap items-center justify-evenly">
        {ProductsFromContext?.length >= 1 && (
          <h1 className="text-3xl hidden font-bold text-center sm:block">
            Tout les produits
          </h1>
        )}
        <div className="w-full flex flex-wrap items-center justify-evenly">
          {ProductsFromContext?.length >= 1 ? (
            ProductsFromContext?.map((product, index) => (
              <div
                className="flex flex-col justify-center items-center py-10 cursor-pointer"
                onClick={() => goToProduct(product._id)}
              >
                <img
                  className="h-[180px] w-[180px] object-cover"
                  src={product.images[0]}
                  alt={product.name}
                />
                <p className="text-lg font-semibold mt-4">{product.name}</p>
                <p className="text-sm text-gray-400">
                  <font className="font-bold text-gray-600">
                    {product.price}
                  </font>
                  DZD
                </p>
              </div>
            ))
          ) : (
            <h1>Aucun produit</h1>
          )}
        </div>
      </div>
    </div>
  );
}

export default Main;
