import { useContext, useEffect, useState } from "react";
import { FacebookPixel } from "react-use-facebook-pixel";
import { getStore } from "../api/storeSlice";
import { subdomainContext, ThemeContext, ThemeProvider } from "./context";

const useFacebookPixel = () => {
  const ThemeData = useContext(ThemeContext);
  const pixelID = async () => {
    if (ThemeData?.pixelId) return ThemeData?.pixelId;
  };
  const [facebookPixel, setFacebookPixel] = useState<FacebookPixel | null>(
    null
  );

  useEffect(() => {
    const initializeFacebookPixel = async () => {
      const pixel = new FacebookPixel({
        pixelID: await pixelID(),
      });
      pixel.init({});
      setFacebookPixel(pixel);
    };

    initializeFacebookPixel();
  }, []);

  return facebookPixel;
};

export default useFacebookPixel;
