import ReactDOM from "react-dom/client";
import "./index.css";
import Main from "./components/Main";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProductDetails from "./components/ProductDetails";
import Header from "./components/Header";
import { ThemeProvider } from "./services/context";
import { Toaster } from "react-hot-toast";
import Cart from "./components/Cart";
import Order from "./components/Order";
import Success from "./components/Success";

const root = ReactDOM.createRoot(document.getElementById("root"));
// function RedirectToExternal() {
//   // window.location.href = "https://sou9i.vercel.app";
//   return null;
// }

root.render(
  <ThemeProvider>
    <BrowserRouter>
      <Toaster
        toastOptions={{
          position: "top-right",
          style: {
            background: "white",
            color: "black",
          },
        }}
      />
      <Header />
      <Routes>
        {/* <Route path="/:tenantPath" element={<Main />} /> */}
        <Route path="/" element={<Main />} />
        <Route path="/:productId" element={<ProductDetails />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/order" element={<Order />} />
        <Route path="/success/:customerId" element={<Success />} />
      </Routes>
    </BrowserRouter>
  </ThemeProvider>
);

reportWebVitals();
