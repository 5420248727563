import React, { useContext, useEffect, useState } from "react";
import { getProduct } from "../api/productSlice";
import { LoadingContext, ThemeProvider } from "../services/context";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";

function Cart() {
  const Products = JSON.parse(localStorage.getItem("cart")) || [];
  const [cart, setCart] = useState([]);
  const data = useContext(ThemeProvider);
  const navigate = useNavigate();
  const isLoading = useContext(LoadingContext);
  const clearCart = () => {
    localStorage.setItem("cart", JSON.stringify([]));
    setCart([]);
  };
  useEffect(() => {
    if (!localStorage.getItem("cart")) {
      localStorage.setItem("cart", JSON.stringify([]));
    }
    for (const product of Products) {
      const fetchProduct = async () => {
        const data = await getProduct(product._id);
        setCart((prev) => [...prev, { quantity: product.quantity, ...data }]);
      };
      fetchProduct();
    }
  }, []);
  const checkout = () => {
    navigate(`/order`);
  };
  return (
    <div className="mt-3 flex flex-col gap-3 mx-4">
      {isLoading && <Loader />}
      {cart.length > 0 ? (
        <div
          onClick={clearCart}
          className="cursor-pointer flex items-center justify-center bg-[#ff0000bb] py-3 rounded-xl"
        >
          <h3 className="text-md font-semibold text-white">Vider mon panier</h3>
        </div>
      ) : (
        <h3 className="text-md font-semibold text-center">
          Votre panier est vide
        </h3>
      )}
      {cart.map((product, index) => {
        return (
          <div
            key={index}
            className="flex items-center gap-5 p-3 rounded-xl"
            style={{ backgroundColor: `${data?.main_color}55` }}
          >
            <img
              src={product.images[0]}
              alt={product.name}
              className="w-20 h-20 object-cover rounded-md shadow-xl"
            />
            <div className="flex flex-col">
              <h3>{product.name}</h3>
              <p>
                <font className="font-bold">{product.price}</font> DA
              </p>
            </div>
            <p
              className="absolute right-10 h-7 w-7 rounded-full flex items-center justify-center"
              style={{ backgroundColor: `${data?.secondary_color}55` }}
            >
              {product.quantity}
            </p>
          </div>
        );
      })}
      {cart.length > 0 && (
        <div
          onClick={checkout}
          className="cursor-pointer flex items-center justify-center bg-green-500 py-3 rounded-xl"
        >
          <h3 className="text-md font-semibold text-white">Acheter</h3>
        </div>
      )}
    </div>
  );
}

export default Cart;
