import { Splide, SplideSlide } from "@splidejs/react-splide";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getProduct } from "../api/productSlice.js";
import { FaCartShopping } from "react-icons/fa6";
import { IoBagAdd } from "react-icons/io5";
import { subdomainContext, ThemeProvider } from "../services/context.js";
import useFacebookPixel from "../services/usePixel.ts";
import { TrackableEventNameEnum } from "react-use-facebook-pixel";
import toast from "react-hot-toast";
import Order from "./Order.jsx";
import Loader from "./Loader.jsx";

function ProductDetails() {
  const subdomain = useContext(subdomainContext);
  const pixel = useFacebookPixel();
  const data = useContext(ThemeProvider);
  const { productId } = useParams();
  const [productData, setProductData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showBuyForm, setShowBuyForm] = useState(false);
  document.body.style.backgroundColor = `${data?.main_color}66`;
  useEffect(() => {
    pixel?.trackEvent(TrackableEventNameEnum.PageView, {
      contentName: "Product Page",
    });
  }, []);

  useEffect(() => {
    async function fetchProduct() {
      const product = await getProduct(productId);
      setProductData(product);
      setIsLoading(false);
    }
    fetchProduct();
  }, []);
  const addToCart = () => {
    pixel?.trackEvent(TrackableEventNameEnum.AddToCart, {
      productId: productData._id,
      quantity: 1,
    });
    if (!localStorage.getItem("cart")) {
      localStorage.setItem("cart", JSON.stringify([]));
    }
    if (localStorage.getItem("cart").includes(productData._id)) {
      const cart = JSON.parse(localStorage.getItem("cart"));
      const quantity = cart.find(
        (item) => item._id === productData._id
      ).quantity;
      cart.find((item) => item._id === productData._id).quantity = quantity + 1;
      localStorage.setItem("cart", JSON.stringify(cart));
      return;
    }
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    cart.push({ ...productData, quantity: 1 });
    localStorage.setItem("cart", JSON.stringify(cart));
  };
  return (
    <>
      <div className="p-5 flex sm:flex-col flex-row sm:gap-5 gap-10 mb-28 sm:mt-0 mt-10">
        {isLoading && <Loader />}
        {!showBuyForm && (
          <div
            className={`buy-button flex border-[3px] justify-around gap-7 items-center fixed bottom-5 rounded-3xl w-[90%] p-4 backdrop-blur-lg left-[50%] transform translate-x-[-50%] z-[10]`}
            style={{
              backgroundColor: `${data?.main_color}BB`,
              borderColor: `${data?.main_color}EE`,
            }}
          >
            <h1
              className={`w-[40%] cursor-pointer flex items-center gap-2 text-md font-bold text-center scale-100 transition-all duration-300 hover:scale-110`}
              style={{ color: `${data?.titles_color}cc` }}
              onClick={addToCart}
            >
              <IoBagAdd style={{ fontSize: "1.5rem" }} /> Panier
            </h1>
            <h1
              onClick={() => {
                setShowBuyForm(true);
                setTimeout(() => {
                  window.scrollTo({
                    top: window.outerHeight,
                    behavior: "smooth",
                  });
                }, 100);
              }}
              className="w-[40%] cursor-pointer flex items-center gap-2 text-xl font-bold text-center scale-100 transition-all duration-300 hover:scale-110"
              style={{ color: `${data?.titles_color}` }}
            >
              <FaCartShopping
                style={{ fontSize: "1.5rem", marginRight: "5px" }}
              />
              Acheter
            </h1>
          </div>
        )}
        <div className="flex md:flex-col justify-center items-center md:w-full w-[35%]">
          <Splide
            className="mb-5"
            options={{
              rewind: true,
              speed: 1000,
              arrows: false,
              pagination: true,
            }}
          >
            {productData &&
              productData.images &&
              productData.images.map((image, index) => (
                <SplideSlide
                  key={index}
                  className={`flex justify-center items-center`}
                >
                  <img
                    src={image}
                    className={`h-[350px] w-[350px] object-cover`}
                  />
                </SplideSlide>
              ))}
          </Splide>
          {productData &&
            productData.images &&
            productData.images.length > 1 && (
              <div className="flex sm:flex-row flex-col items-center justify-center">
                {productData &&
                  productData.images &&
                  productData.images.map((image, index) => (
                    <img
                      src={image}
                      className={`h-[70px] w-[70px] object-cover border`}
                    />
                  ))}
              </div>
            )}
        </div>
        <div className="flex flex-col gap-3 md:w-full w-[65%]">
          <h2 className="text-3xl font-bold">{productData.name}</h2>
          {productData && productData.brand && <h5>{productData.brand}</h5>}
          <h5 className="break-all">{productData.description}</h5>
          <h5 className="text-2xl font-bold text-green-500 drop-shadow-[0_0_2px_rgba(0,0,0,1)]">
            {productData.price} DA
          </h5>
        </div>
      </div>
      {showBuyForm && (
        <Order
          className="bg-[#ffffff55] p-5 rounded-lg"
          product={productData}
          quantity={1}
          price={productData.price}
          sellerId={productData.sellerId}
        />
      )}
    </>
  );
}

export default ProductDetails;
