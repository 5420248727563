import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getOrder } from "../api/orderSlice";

function Success() {
  const { customerId } = useParams();
  const [orderDetails, setOrderDetails] = useState(null);
  useEffect(() => {
    async function fetchData() {
      const data = await getOrder({ customerId });
      setOrderDetails(data[0]);
    }
    fetchData();
  }, []);
  return (
    <div className="flex flex-col gap-[1rem] items-start justify-center w-full p-[2rem]">
      <div className="flex items-center gap-[1rem]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-[5rem] h-[5rem]"
          viewBox="0 0 512 512"
        >
          <path
            fill="#22c55e"
            d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"
          />
        </svg>
        <div className="flex flex-col items-start w-full">
          <p>Commande #{customerId}</p>
          <h1>Merci {orderDetails?.name}!</h1>
        </div>
      </div>

      <div className="flex flex-col items-start w-full border-2 border-[#151515] rounded-md p-[1vh_2vh]">
        <h1 className="font-[800]">Ta commande est envoyé</h1>
        <p>
          Nous avons bien reçu votre commande et nous allons la traiter dans les
          plus brefs délais. revenez sur cette page pour des mises à jour sur
          l'état de votre commande.
          <br />
          Merci !
        </p>
      </div>
      <div className="flex flex-col items-start w-full border-2 border-[#151515] rounded-md p-[1vh_2vh]">
        <h1 className="font-[800]">Informations sur le client</h1>
        <div>
          <p>Nom: {orderDetails?.name}</p>
          <p>
            Adresse: {orderDetails?.commune}, {orderDetails?.wilaya}
          </p>
          <p>Telephone: {orderDetails?.phone}</p>
        </div>
      </div>
    </div>
  );
}

export default Success;
