import React, { createContext, useState, useEffect } from "react";
import { getStore } from "../api/storeSlice";

export const ThemeContext = createContext();
export const ProductsContext = createContext();
export const LoadingContext = createContext();
export const subdomainContext = createContext();
let subdomain;
const host = window.location.host;
const arr = host.split(".").slice(0, host.includes("localhost") ? -1 : -2);
if (host.length > 0) subdomain = arr[0];
export function ThemeProvider({ children }) {
  const [themeData, setThemeData] = useState({
    shop_name: "Amido",
    titles_color: "#000",
    secondary_color: "#fff",
    main_color: "#fff",
    font: "Poppins",
    announcement1: "",
    announcement2: "",
    products: [],
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchHome = async () => {
      try {
        const res = await getStore(subdomain);
        if (res) {
          setThemeData({
            shop_name: res.store.storeName || "StoreBladi",
            titles_color: res.store?.theme[0]?.colors[0] || "#000000",
            secondary_color: res.store?.theme[0]?.colors[1] || "#c2c2c2",
            main_color: res.store?.theme[0]?.colors[2] || "#ffffff",
            font: res.store?.theme[0]?.font || "Poppins",
            announcement1: res.store?.announcement1,
            announcement2: res.store?.announcement2,
            products: res.store?.products || [],
            pixelId: res.store?.shopInfo?.pixelId,
          });
          document.title = res.store.storeName;
          document.body.style.backgroundColor = `${res.store.theme[0].colors[2]}66`;
        }
      } catch (err) {
        console.error("Fetch Error:", err);
      } finally {
        setIsLoading(false);
        console.log("Store data fetched successfully");
      }
    };
    fetchHome();
  }, []);

  return (
    <ThemeContext.Provider value={themeData}>
      <ProductsContext.Provider value={themeData.products}>
        <LoadingContext.Provider value={isLoading}>
          <subdomainContext.Provider value={subdomain}>
            {children}
          </subdomainContext.Provider>
        </LoadingContext.Provider>
      </ProductsContext.Provider>
    </ThemeContext.Provider>
  );
}
